import { CompanionTypeDto } from './companion';
import { EligibilityTypeDto } from './eligibility';
import { ServiceTypeDto } from './service';

export interface AdmissionDto {
  childEndDate?: string;
  parentEndDate?: string;
  companionEndDate?: string;
  eligibilityTypes?: EligibilityTypeDto[];
  companionType: CompanionTypeDto;
  serviceEndDate?: string;
  serviceStartDate?: string;
  serviceType: ServiceTypeDto;
  status: AdmissionStatusDto;
}

export enum AdmissionStatusDto {
  Null = 'Null',
  Active = 'Active',  
  Inactive = 'Inactive',    
  Revoked = 'Revoked',
}