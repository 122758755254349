import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ApiCallStatus } from '../../api/shared';
import PlanTripBanner from '../../components/plan-trip/plan-trip-banner/PlanTripBanner';
import PlanTripResume from '../../components/plan-trip/plan-trip-resume/PlanTripResume';
import PlanTripStep1 from '../../components/plan-trip/plan-trip-step-1/PlanTripStep1';
import PlanTripStep2 from '../../components/plan-trip/plan-trip-step-2/PlanTripStep2';
import PlanTripStep3 from '../../components/plan-trip/plan-trip-step-3/PlanTripStep3';
import PlanTripStepper from '../../components/plan-trip/plan-trip-stepper/PlanTripStepper';
import CustomerError from '../../layout/customer-error/CustomerError';
import Loader from '../../layout/loader/Loader';
import { selectCustomer, selectHasCustomer } from '../../store/customerSlice';
import {
  selectActiveStep,
  selectPlanJourneyCallStatus,
  selectSaveJourneyCallStatus,
  resetPlanTrip,
  setAssisteDevice,
  selectActiveStepRoundTrip,
  selectFormPlanTrip,
} from '../../store/planTripSlice';
import './PlanTrip.scss';
import { CustomerAssistiveDeviceDto } from '../../models/customer';

const PlanTrip = () => {
  const activeStep = useSelector(selectActiveStep);
  const activeStepRoundTrip = useSelector(selectActiveStepRoundTrip);
  const { t } = useTranslation();
  const [isButtonClicked] = useState(false);
  const planJourneyCallStatus = useSelector(selectPlanJourneyCallStatus);
  const saveJourneyCallStatus = useSelector(selectSaveJourneyCallStatus);
  const hasCustomer = useSelector(selectHasCustomer);
  const customer = useSelector(selectCustomer);

  const dispatch = useDispatch();
  const formPlanTrip = useSelector(selectFormPlanTrip);

  useEffect(() => {
    dispatch(resetPlanTrip());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customer?.identifier && customer?.identifier !== 0) {
      if ((customer?.assistiveDevices?.length ?? 0) > 0) {
        let tempAssistiveDevice: string[] = [];
        customer.assistiveDevices?.map((item: CustomerAssistiveDeviceDto) => {
          if (item.isPrincipal || !item.assistiveDevice.isPublishable) {
            tempAssistiveDevice = [...tempAssistiveDevice, item.assistiveDevice.identifier];
          }
          if (tempAssistiveDevice.length > 0) {
            dispatch(setAssisteDevice(tempAssistiveDevice));
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  return (
    <Box component="div" className="plan-trip-form">
      {hasCustomer && <PlanTripStepper></PlanTripStepper>}
      {activeStep === 1 && (
        <Box component="div" className={'banners step' + (activeStep + 1)}>
          { activeStepRoundTrip === 0 && 
            <PlanTripResume origin={formPlanTrip.PointA?.addressLocation?.shortFormattedDescription} 
              destination={formPlanTrip.PointB?.addressLocation?.shortFormattedDescription} 
            />
          }
          { activeStepRoundTrip === 1 && 
            <PlanTripResume origin={formPlanTrip.PointB?.addressLocation?.shortFormattedDescription} 
              destination={formPlanTrip.PointA?.addressLocation?.shortFormattedDescription} 
            />
          }
          
          <PlanTripBanner
            title={t('shared.label.important')}
            text={t('plan-trip.step2.bannerText')}
            level="warning"
            closeButton={true}
            showIcon={false}
          />
        </Box>
      )}
      <div className="container-fluid">
        <CustomerError />
        {hasCustomer && <>
          {(planJourneyCallStatus === ApiCallStatus.Pending ||
            saveJourneyCallStatus === ApiCallStatus.Pending) && <Loader></Loader>}
          <Box component="div" className="PlanTripContainer">
            {activeStep === 0 && (
              <PlanTripStep1 submitClicked={isButtonClicked}></PlanTripStep1>
            )}
            {activeStep === 1 && (
              <PlanTripStep2></PlanTripStep2>
            )}
            {(activeStep === 2 || activeStep === 3) && <PlanTripStep3></PlanTripStep3>}
          </Box>
        </>}
      </div>
    </Box>
  );
};

export default PlanTrip;
