import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import customerApi from '../api/customer';
import { CustomerDto } from '../models/customer';
import type { AppDispatch, RootState } from './store';
import { AdmissionStatusDto } from '../models/admission';

export interface CustomerState {
  customerId: {
    value: string;
  };
  customer: {
    value?: CustomerDto;
  };
  isLoading: {
    value: boolean
  };
  hasError: {
    value: boolean
  };
  isCustomerInactive: {
    value: boolean,
  };
}

// Define the initial state
const initialState: CustomerState = {
  customerId: {
    value: '',
  },
  customer: {
    value: undefined,
  },
  isLoading: {
    value: false,
  },
  hasError: {
    value: false,
  },
  isCustomerInactive: {
    value: false,
  },
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<CustomerDto>) => {
      state.customer.value = action.payload;
    },
    setCustomerId: (state, action: PayloadAction<string>) => {
      state.customerId.value = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading.value = action.payload;
    },
    setHasError: (state, action: PayloadAction<boolean>) => {
      state.hasError.value = action.payload;
    },
    setIsCustomerInactive: (state, action: PayloadAction<boolean>) => {
      state.isCustomerInactive.value = action.payload;
    },
  },
});


export const { setCustomer, setIsLoading, setHasError, setCustomerId, setIsCustomerInactive } = customerSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchCustomerAsync(5))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const fetchCustomerAsync = (lang: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const rootState = getState();
  dispatch(setIsLoading(true));
  dispatch(setHasError(false));
  customerApi.getMe(`${lang}-CA`)
    .then((response) => {
      if (response.data) {
        if (response.data?.customer?.admission?.status === AdmissionStatusDto.Active) {
          dispatch(setCustomer(response.data?.customer));
          dispatch(setCustomerId(response.data?.customer?.identifier?.toString() ?? ''));
          dispatch(setIsCustomerInactive(false));
          dispatch(setIsLoading(false));
        } else {
          dispatch(setIsLoading(false));
          dispatch(setIsCustomerInactive(true));
        }
        
      }
    }).catch((error) => {
      dispatch(setIsLoading(false));
      dispatch(setHasError(true));
      console.warn(error);
    });
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCustomer = (state: RootState) => state.customer.customer.value;
export const selectCustomerHasError = (state: RootState) => state.customer.hasError.value;
export const selectCustomerIsLoading = (state: RootState) => state.customer.isLoading.value;
export const selectHasCustomer = (state: RootState) => state.customer.customer.value !== undefined && !!state.customer.customer.value?.identifier;
export const isCustomerInactive = (state: RootState) => state.customer.isCustomerInactive.value;
  
export default customerSlice.reducer;
