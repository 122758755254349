import React from 'react';
import './CustomerError.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isCustomerInactive, selectCustomerHasError } from '../../store/customerSlice';
import ServerError from '../../components/alert/server-error/ServerError';

const CustomerError = () => {
  const { t } = useTranslation();

  const customerHasError = useSelector(selectCustomerHasError);
  const isInactive = useSelector(isCustomerInactive);


  return (
    <>
      {customerHasError && <div className="CustomerError">
        <ServerError>{t('error.customerErrorText')}</ServerError>
      </div>}

      {isInactive && <div className="CustomerError">
        <ServerError title={t('error.inactiveCustomerTitle')}>{t('error.inactiveCustomer')}</ServerError>
      </div>}
    </>
  );
};

export default CustomerError;
